import React from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import { host } from "../../../constants";
const { Dragger } = Upload;
const props = {
  name: "file",
  multiple: true,
  action: host + "/plati/upload/",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      message.success(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  },
  onDrop(e) {
    console.log("Dropped files", e.dataTransfer.files);
  },
};
const PlatiUpload = () => (
  <Dragger {...props}>
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">
      Faceți clic sau trageți fișierul în această zonă pentru a-l încărca
    </p>
    <p className="ant-upload-hint">
      Suport pentru o singură încărcare sau o încărcare în masă. Este strict
      interzisă încărcarea datelor companiei sau a altor fișiere interzise.
    </p>
  </Dragger>
);
export default PlatiUpload;
