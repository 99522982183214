import React, { useEffect, useState } from "react";
import PlatiUpload from "./plati/PlatiUpload";
import axios from "axios";
import { API_URL, getDailyPlati } from "../../constants";
import { DatePicker, Button, Table, theme, Empty, Skeleton } from "antd";
import moment from "moment";
import dayjs from "dayjs";
import useTableSearch from "../../hooks/useTableSearch";

const { RangePicker } = DatePicker;
const dateFormat = "DD-MM-YYYY";

const Plati = () => {
  const [dateRange, setDateRange] = useState([]);
  const [table, setTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [minDate, setMinDate] = useState(
    localStorage.getItem("minDatePlati")
      ? new Date(localStorage.getItem("minDatePlati"))
      : new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [maxDate, setMaxDate] = useState(
    localStorage.getItem("maxDatePlati")
      ? new Date(localStorage.getItem("maxDatePlati"))
      : new Date(new Date().setHours(23, 59, 59, 999))
  );
  const getColumnSearchProps = useTableSearch();

  const getTable = async () => {
    setLoading(true);
    try {
      const res = await axios.post(API_URL + "payment/paid", {
        minDate: minDate,
        maxDate: maxDate,
      });
      if (res.data) {
        setTable(
          res.data.map((el) => {
            return {
              key: el.id,
              CODID: el.Plati && el.Plati.length ? el.Plati[0].CODID : "",
              USERINFO:
                el.Plati && el.Plati.length ? el.Plati[0].USERINFO : "",
              ...el,
            };
          })
        );
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getTable();
  }, []);
  const expandedRowRender = (info) => {
    const columns = [
      {
        title: "Cod ID",
        dataIndex: "CODID",
        key: "codid",
        sorter: (a, b) => a.CODID.localeCompare(b.CODID),
        ...getColumnSearchProps("CODID"),
      },
      {
        title: "Tipul de plată",
        dataIndex: "PAY_TYPE",
        key: "paytype",
        sorter: (a, b) => a.PAY_TYPE.localeCompare(b.PAY_TYPE),
        ...getColumnSearchProps("PAY_TYPE"),
      },
      {
        title: "Data plății",
        dataIndex: "PAY_DATE",
        key: "paydate",
        sorter: (a, b) => new Date(a.PAY_DATE) - new Date(b.PAY_DATE),
        render: (text) => (
          <span>{moment(text).format("DD/MM/YYYY, hh:mm")}</span>
        ),
        ...getColumnSearchProps("PAY_DATE"),
      },
      {
        title: "Info utilizator",
        dataIndex: "USERINFO",
        key: "userinfo",
        sorter: (a, b) => a.USERINFO.localeCompare(b.USERINFO),
        ...getColumnSearchProps("USERINFO"),
      },
      {
        title: "Codul serviciului",
        dataIndex: "CODSERV",
        key: "codserv",
        sorter: (a, b) => a.CODSERV - b.CODSERV,
        ...getColumnSearchProps("CODSERV"),
      },
      {
        title: "Suma",
        dataIndex: "SUMA",
        key: "sum",
        sorter: (a, b) => a.SUMA - b.SUMA,
        ...getColumnSearchProps("SUMA"),
      },
    ];
    const data = [];
    for (let i = 0; i < info.Plati.length; ++i) {
      let element = info.Plati[i];
      data.push({
        key: element.id,
        ...element,
      });
    }
    return (
      <Table
        locale={{
          triggerDesc: "Faceți clic pentru a sorta descrescător",
          triggerAsc: "Faceți clic pentru a sorta crescător",
          cancelSort: "Faceți clic pentru a anula sortarea",
          emptyText: <Empty description={"Nu există date"}></Empty>,
        }}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
    );
  };

  const columns = [
    {
      title: "ID extern",
      dataIndex: "external_id",
      key: "external_id",
      sorter: (a, b) => a.external_id.localeCompare(b.external_id),
      ...getColumnSearchProps("external_id"),
    },
    {
      title: "Paynet ID",
      dataIndex: "paynet_payment_id",
      key: "paynet_payment_id",
      sorter: (a, b) => a.paynet_payment_id.localeCompare(b.paynet_payment_id),
      ...getColumnSearchProps("paynet_payment_id"),
    },
    {
      title: "CODID",
      dataIndex: "CODID",
      key: "CODID",
      render: (_, record) => {
        if (record.Plati.length > 0) {
          return record.Plati[0].CODID;
        }
        return null;
      },
      sorter: (a, b) => {
        if (a.Plati.length === 0 && b.Plati.length === 0) {
          return 0;
        } else if (a.Plati.length === 0) {
          return -1;
        } else if (b.Plati.length === 0) {
          return 1;
        }
        return a.Plati[0].CODID.localeCompare(b.Plati[0].CODID);
      },
      ...getColumnSearchProps("CODID"),
    },
    {
      title: "USERINFO",
      dataIndex: "USERINFO",
      key: "USERINFO",
      render: (_, record) => {
        if (record.Plati.length > 0) {
          return record.Plati[0].USERINFO;
        }
        return null;
      },
      sorter: (a, b) => {
        if (a.Plati.length === 0 && b.Plati.length === 0) {
          return 0;
        } else if (a.Plati.length === 0) {
          return -1;
        } else if (b.Plati.length === 0) {
          return 1;
        }
        return a.Plati[0].USERINFO.localeCompare(b.Plati[0].USERINFO);
      },
      ...getColumnSearchProps("USERINFO"),
    },
    {
      title: "Suma",
      dataIndex: "Amount",
      key: "Amount",
      sorter: (a, b) => (a.Amount > b.Amount ? -1 : 1),
      ...getColumnSearchProps("Amount"),
    },
    {
      title: "Data de plată",
      dataIndex: "status_date",
      key: "status_date",
      sorter: (a, b) => new Date(a.status_date) - new Date(b.status_date),
      render: (text) => <span>{moment(text).format("DD/MM/YYYY, hh:mm")}</span>,
      ...getColumnSearchProps("status_date"),
    },
  ];

  const handleDownload = async () => {
    await getDailyPlati(minDate, maxDate);
  };

  function handleMinDate(date) {
    localStorage.setItem("minDatePlati", date);
    setMinDate(date);
  }

  function handleMaxDate(date) {
    localStorage.setItem("maxDatePlati", date);
    setMaxDate(date);
  }

  useEffect(() => {
    if (dateRange[0]) handleMinDate(dateRange[0]);
    if (dateRange[1]) handleMaxDate(dateRange[1]);
  }, [dateRange[0], dateRange[1]]);

  const handleDateRangeChange = (dates) => {
    if (dates && dates.length === 2) {
      const newStartDate = new Date(dates[0]);
      newStartDate.setHours(0, 0, 0, 0);

      const newEndDate = new Date(dates[1]);
      newEndDate.setHours(23, 59, 59, 999);

      setDateRange([newStartDate, newEndDate]);
    } else {
      setDateRange([null, null]);
    }
  };

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <div className="d-flex flex-column gap-5">
      <div className="d-flex justify-content-between flex-column-reverse flex-xl-row gap-5">
        <div
          className="p-sm-5 p-4 d-flex w-100 flex-column gap-5"
          style={{ background: colorBgContainer, borderRadius: borderRadiusLG }}
        >
          <div className="row">
            <div>
              <div className="form-group">
                <label htmlFor="dateRangePicker">
                  Selectați intervalul de date:
                </label>
                <RangePicker
                  id="dateRangePicker"
                  style={{ width: "100%" }}
                  placeholder={["Data de începere", "Data finală"]}
                  defaultValue={[
                    dayjs(moment(minDate).format(dateFormat), dateFormat),
                    dayjs(moment(maxDate).format(dateFormat), dateFormat),
                  ]}
                  format={dateFormat}
                  onChange={handleDateRangeChange}
                />
              </div>
            </div>
          </div>
          <div className="d-flex gap-3 flex-wrap">
            <div>
              <Button type="primary" onClick={getTable}>
                Afiseaza plati
              </Button>
            </div>
            <div>
              <Button type="primary" onClick={handleDownload}>
                Descarca
              </Button>
            </div>
          </div>
        </div>
        <div className="w-100">
          <PlatiUpload />
        </div>
      </div>
      {loading ? (
        <Skeleton active />
      ) : (
        <Table
          locale={{
            triggerDesc: "Faceți clic pentru a sorta descrescător",
            triggerAsc: "Faceți clic pentru a sorta crescător",
            cancelSort: "Faceți clic pentru a anula sortarea",
            emptyText: <Empty description={"Nu există date"}></Empty>,
          }}
          expandable={{
            expandedRowRender: (record) => expandedRowRender(record),
            defaultExpandedRowKeys: ["0"],
          }}
          scroll={{ x: 900 }}
          columns={columns}
          dataSource={table}
        />
      )}
    </div>
  );
};

export default Plati;
