import React from "react";
import { Menu } from "antd";
const TopicMenu = ({ topics, selectedKey, changeSelectedKey }) => {
  const styledTopics = [];
  topics.forEach((topic, index) =>
    styledTopics.push({
      label: topic.name,
      key: index,
      onClick: changeSelectedKey,
    })
  );

  return (
    <Menu mode="inline" items={styledTopics} selectedKeys={[selectedKey]} />
  );
};
export default TopicMenu;
