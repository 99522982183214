import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import TopicMenu from "../menu/TopicMenu";
import NavBar from "../navbar/Navbar";
import SideBar from "../sidebar/Sidebar";
import { Layout } from "antd";
import Plati from "../content/Plati";
import Indice from "../content/Indice";

const Main = () => {
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.user.currentUser);
  const topics = [
    { name: "Indici", content: <Indice /> },
    { name: "Plati", content: <Plati /> },
  ];
  const [contentIndex, setContentIndex] = useState(0);
  const [selectedKey, setSelectedKey] = useState("0");

  useEffect(() => {
    setSelectedKey(localStorage.getItem("tab"));
    setContentIndex(+localStorage.getItem("tab"));
  }, []);

  useEffect(() => {
    if (!currentUser) {
      console.log(currentUser);
      navigate("/login");
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      navigate(`/${parseInt(selectedKey) ? "plati" : "indice"}`);
    }
  }, [selectedKey, currentUser]);

  const changeSelectedKey = (event) => {
    const key = event.key;
    setSelectedKey(key);
    setContentIndex(+key);
    localStorage.setItem("tab", key);
  };
  const Menu = (
    <TopicMenu
      topics={topics}
      selectedKey={selectedKey}
      changeSelectedKey={changeSelectedKey}
    />
  );
  return (
    <div className="App">
      <NavBar menu={Menu} />
      <Layout>
        <SideBar menu={Menu} />
        <Layout.Content className="content">
          {topics[contentIndex].content}
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default Main;
